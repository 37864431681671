
window.addEventListener('DOMContentLoaded', function(){
  $('select').change(function () {
    var speed = 400;
    var href = $(this).val();
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    return false;
  });
});
