import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import { CountUp } from 'countup.js';

const fvKeyName = 'visited';
const fvKeyValue = true;

window.addEventListener('DOMContentLoaded', function() {
  const loader = document.getElementById('loader');
  loader.classList.add('loaded');
  const targetPage = document.querySelector('body');
  const targetPageId = targetPage.getAttribute('id');
  if (targetPageId == "index") {
    if (!sessionStorage.getItem(fvKeyName)) {
      //sessionStorageにキーと値を追加
      sessionStorage.setItem(fvKeyName, fvKeyValue);
      //初回アクセス時の処理
      document.getElementById('index').style.opacity = "0";
      this.window.addEventListener('load',function(){
        fvGsapMove();
      })
    } else {
      //初回アクセス以外はFVアニメーションはなし
      document.getElementById('target').textContent = "1,367";
    }
  }
  gsapMove();
});

function isPcSize() {
  const windowWidth = window.innerWidth;
  const breakPointMd = 780;
  const val = (windowWidth > breakPointMd);
  return val;
}

function fvGsapMove() {
  const isPcSizeFlg = isPcSize();
  const gsapObjs = {};
  const idObjs = {};


  if (isPcSizeFlg) {
    gsapObjs.obj01 = "pc_middle_01_01";
    gsapObjs.obj02 = "pc_middle_01_02";
    gsapObjs.obj03 = "pc_middle_01_03";
    gsapObjs.obj04 = "pc_middle_02";
    gsapObjs.obj06 = "pc_left_01";
    gsapObjs.obj07 = "pc_left_02";

    const svgFvPc = document.getElementById("svg_fv_pc").contentDocument;
    idObjs.obj01 = svgFvPc.getElementById(gsapObjs.obj01);
    idObjs.obj02 = svgFvPc.getElementById(gsapObjs.obj02);
    idObjs.obj03 = svgFvPc.getElementById(gsapObjs.obj03);
    idObjs.obj04 = svgFvPc.getElementById(gsapObjs.obj04);

    idObjs.obj06 = svgFvPc.getElementById(gsapObjs.obj06);
    idObjs.obj07 = svgFvPc.getElementById(gsapObjs.obj07);

  } else {
    gsapObjs.obj01 = "sp_middle_01_01";
    gsapObjs.obj02 = "sp_middle_01_02";
    gsapObjs.obj03 = "sp_middle_01_03";
    gsapObjs.obj04 = "sp_middle_02";
    gsapObjs.obj06 = "sp_left_01";
    gsapObjs.obj07 = "sp_left_02";

    const svgFvSp = document.getElementById("svg_fv_sp").contentDocument;
    idObjs.obj01 = svgFvSp.getElementById(gsapObjs.obj01);
    idObjs.obj02 = svgFvSp.getElementById(gsapObjs.obj02);
    idObjs.obj03 = svgFvSp.getElementById(gsapObjs.obj03);
    idObjs.obj04 = svgFvSp.getElementById(gsapObjs.obj04);
    idObjs.obj06 = svgFvSp.getElementById(gsapObjs.obj06);
    idObjs.obj07 = svgFvSp.getElementById(gsapObjs.obj07);
  }

  // fvアニメーション
  const tl = gsap.timeline();
  if (isPcSizeFlg) {
    tl.to("#index", { // body
      opacity: 1,
    }).fromTo(idObjs.obj01, { // 世
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.5,
      ease: "power2.inOut"
    }, '+=0.5').fromTo(idObjs.obj02, { // 界
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.5,
      ease: "power2.inOut"
    }, '-=1').fromTo(idObjs.obj03, { // No.1
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.5,
      ease: "power2.inOut"
    }, '-=1').fromTo(idObjs.obj04, { // 複数のオブジェクト
      y: 10,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: 1.2,
      ease: "power2.inOut"
    }, '-=0.8').fromTo('#img_fv_pc_bg', { // グラデーション背景とその他
      opacity: 0
    }, {
      opacity: 1,
      duration: 1,
      ease: "power2.inOut"
    }, '-=1').fromTo(idObjs.obj06, { // 球体
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.2,
      ease: "power2.inOut"
    }, '<').fromTo(idObjs.obj07, { // 線
      y: -20,
      opacity: 0,
      rotate: -3
    }, {
      y: 0,
      opacity: 1,
      rotate: 0,
      duration: 1.3,
      ease: "power2.inOut",
    }, '<').fromTo(".menu-trigger", { // ハンバーガーメニュー
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    }, '<').add(function () { // 1367
      const easingFn = function (t, b, c, d) {
        var ts = (t /= d) * t;
        var tc = ts * t;
        return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
      }
      const options = {
        duration: 1.2,
        easingFn,
      };
      let demo = new CountUp('target', 1367, options);
      if (!demo.error) {
        demo.start();
      } else {
        console.error(demo.error);
      }
    }, '+=0.3');
  } else {
    tl.to("#index", { // body
      opacity: 1,
    }).fromTo(idObjs.obj01, { // 世
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.5,
      ease: "power2.inOut"
    }, '+=0.5').fromTo(idObjs.obj02, { // 界
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.5,
      ease: "power2.inOut"
    }, '-=1').fromTo(idObjs.obj03, { // No.1
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.5,
      ease: "power2.inOut"
    }, '-=1').fromTo(idObjs.obj04, { // 複数のオブジェクト
      y: 10,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: 1.2,
      ease: "power2.inOut"
    }, '-=0.8').fromTo("#img_fv_sp_bg", { // グラデーション背景
      opacity: 0
    }, {
      opacity: 1,
      duration: 1,
      ease: "power2.inOut"
    }, '-=1').fromTo(idObjs.obj06, { // 球体
      opacity: 0
    }, {
      opacity: 1,
      duration: 1.2,
      ease: "power2.inOut"
    }, '<').fromTo(idObjs.obj07, { // 線
      y: -20,
      opacity: 0,
      rotate: -3
    }, {
      y: 0,
      opacity: 1,
      rotate: 0,
      duration: 1.2,
      ease: "power2.inOut",
    }, '<').fromTo(".menu-trigger", { // ハンバーガーメニュー
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      ease: "power2.inOut",
    }, '<').add(function () { // 1367
      const easingFn = function (t, b, c, d) {
        var ts = (t /= d) * t;
        var tc = ts * t;
        return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
      }
      const options = {
        duration: 1.2,
        easingFn,
      };
      let demo = new CountUp('target', 1367, options);
      if (!demo.error) {
        demo.start();
      } else {
        console.error(demo.error);
      }
    }, '+=0.3');
  }
};

/*
    animationさせる要素に以下のどれかを付加する。
    '.js-animation-to-up-el' 下から上にフェードイン
    '.js-animation-to-left-el' 右から左にフェードイン
    '.js-animation-to-right-el' 左から右にフェードイン
*/
function gsapMove(){
  if (isPcSize()) {
    const itemsToUp = gsap.utils.toArray('.js-animation-to-up-el'); // 下から上にフェードイン
    itemsToUp.forEach((itemsToUp) => {
      gsap.fromTo(itemsToUp,
        {
          y: 50,
          autoAlpha: 0
        },
        {
          y: 0,
          autoAlpha: 1,
          delay: 0.3,
          duration: 1.2,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: itemsToUp.parentNode,
            start: 'top bottom'
          }
        }
      );
    });

    const itemsToLeft = gsap.utils.toArray('.js-animation-to-left-el'); // 右から左にフェードイン
    itemsToLeft.forEach((itemsToLeft) => {
      gsap.fromTo(itemsToLeft,
        {
          x: 50,
          autoAlpha: 0
        },
        {
          x: 0,
          autoAlpha: 1,
          delay: 0.3,
          duration: 1.2,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: itemsToLeft.parentNode,
            start: 'top bottom'
          }
        }
      );
    });

    const itemsToRight = gsap.utils.toArray('.js-animation-to-right-el'); // 左から右にフェードイン
    itemsToRight.forEach((itemsToRight) => {
      gsap.fromTo(itemsToRight,
        {
          x: -50,
          autoAlpha: 0
        },
        {
          x: 0,
          autoAlpha: 1,
          delay: 0.3,
          duration: 1.2,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: itemsToRight.parentNode,
            start: 'top bottom'
          }
        }
      );
    });
  }
}
