//
// メニュー
// ------------------------------
window.addEventListener('DOMContentLoaded', function () {
  $(".menu-trigger").on("click", function () {
    $(this).toggleClass("active");
    return false;
  });
  $(".gmenu a").on("click", function () {
    $(".gmenu").toggleClass("active");
    $(".menu-trigger").toggleClass("active");
  });
  var $wrap = $(".gmenu");
  $(".menu-trigger").on("click", function () {
    $wrap.toggleClass("open");
  });
  $("#menu-trigger").on("click", function () {
    $wrap.removeClass("open");
  });
  var $wrap = $(".gmenu");
  $(".gmenu a").on("click", function () {
    $wrap.removeClass("open");
  });
  //
  // スムーズスクロール
  // ------------------------------
  $(function () {
    var urlHash = location.hash;
    if (urlHash) {
      $("body,html").stop().scrollTop(0);
      setTimeout(function () {
        var target = $(urlHash);
        var position = target.offset().top;
        $("body,html").stop().animate({ scrollTop: position }, 700);
      }, 100);
    }
    $('a[href^="#"]').click(function () {
      var href = $(this).attr("href");
      var target = $(href);
      var position = target.offset().top;
      $("body,html").stop().animate({ scrollTop: position }, 700);
    });
  });
  //
  // 上へ戻るボタン
  // ------------------------------
  $("#pagetop").hide();//ボタンを非表示にする
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 100) { //ページの上から100pxスクロールした時
      $("#pagetop").fadeIn("fast"); //ボタンがフェードインする
    } else {
      $("#pagetop").fadeOut("fast");//ボタンがフェードアウトする
    }
  });


  //スムーススクロール設定
  $('#pagetop').click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 800);//スムーススクロールの速度
    return false;
  });
});
